import { filterBooleanQuery } from "../data/factories/EsQueriesFactory";

const catalog_records_index_settings = {
    index_name: "catalog_records",
    search_attributes: [
        { field: "id", weight: 3 },
        "skc_sysno",
        "cnb_sysno",
        "title",
        "cnb",
        "signature",
        "isxn",
        "barcodes",
        "publisher",
    ],
    result_attributes: [
        "id",
        "main_record",
        "cnb",
        "language",
        "signature",
        "isxn",
        "barcodes",
        "skc_sysno",
        "skc_record",
        "skc_difference",
        "skc_record_flags",
        "cnb_sysno",
        "cnb_record",
        "cnb_difference",
        "cnb_record_flags",
        "has_documents",
        "has_active_documents",
        "added_at",
        "updated_at",
        "indexed_at",
        "material_type",
        "title",
        "size",
        "location_uri",
        "publisher",
        "publishing_place",
        "publishing_year",
        "languages",
        "frequencies",
    ],
    highlight_attributes: ["id"],
    sorting: {
        catalog_records: [{ field: "updated_at", order: "desc" }],
        _newest: { field: "added_at", order: "desc" },
        _oldest: { field: "added_at", order: "asc" },
    },
    facet_attributes: [
        {
            attribute: "id",
            field: "id",
            type: "string",
            translateAs: "text",
        },
        {
            attribute: "skc_record_flags",
            field: "skc_record_flags",
            type: "string",
            translateAs: "type",
            tType: "skc-record-flags",
        },
        {
            attribute: "cnb_record_flags",
            field: "cnb_record_flags",
            type: "string",
            translateAs: "type",
            tType: "cnb-record-flags",
        },
        {
            attribute: "material_type",
            field: "material_type",
            type: "string",
            translateAs: "type",
            tType: "material-type",
        },
        {
            attribute: "skc_difference_tag_facets",
            field: "skc_difference_tag_facets",
            type: "string",
            translateAs: "text",
        },
        {
            attribute: "cnb_difference_tag_facets",
            field: "cnb_difference_tag_facets",
            type: "string",
            translateAs: "text",
        },
        {
            attribute: "language",
            field: "language",
            type: "string",
            translateAs: "text",
        },
        {
            attribute: "has_documents",
            field: "has_documents",
            type: "numeric",
            filterQuery: filterBooleanQuery,
            stateAs: "boolean",
            translateAs: "boolean",
            init: "1",
        },
        {
            attribute: "has_active_documents",
            field: "has_active_documents",
            type: "numeric",
            filterQuery: filterBooleanQuery,
            stateAs: "boolean",
            translateAs: "boolean",
            init: "1",
        },
    ],
};

export default catalog_records_index_settings;
