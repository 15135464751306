import React from "react";
import { useTranslation } from "react-i18next";
import {
    Frequency,
    IndexDocument,
    UpdateAttributes,
} from "../../adapters/ApiDocuments";
import { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import ExtractionPriorityEditor from "../../components/editors/ExtractionPriorityEditor";
import EditorProps from "../../components/editors/Editor";
import { useDocuments } from "../../contexts/DocumentsContext";
import { formatDatetime } from "../../data/factories/DateFactory";
import ExternalLinkButton from "../../components/buttons/ExternalLinkButton";
import DigitizationStateTag from "../../components/tags/DigitizationStateTag";
import MaterialTypeTag from "../../components/tags/MaterialTypeTag";
import IssuanceTypeTag from "../../components/tags/IssuanceTypeTag";
import { createCategoryPathLabel } from "../../data/factories/LabelsFactory";
import DocumentFlagTag from "../../components/tags/DocumentFlagTag";
import LabeledComponentsBox from "../../components/layouts/LabeledComponentsBox";
import ExtractionPriorityTag from "../../components/tags/ExtractionPriorityTag";
import { useNotification } from "../../contexts/NotificationContext";
import NumberEditor from "../../components/editors/NumberEditor";
import CategoryPathEditor from "../../components/editors/CategoryPathEditor";
import ActionAccessComponent from "../../components/access/ActionAccessComponent";
import { OwnedItemType, useAccess } from "../../contexts/AccessContext";
import { ActionType } from "../../types/ActionType";
import DeleteButton from "../../components/buttons/DeleteButton";
import InternalLinkButton from "../../components/buttons/InternalLinkButton";

interface DocumentDetailsFieldType {
    attribute: string;
    transform?: (value: any) => any;
    default?: any;
    editor?: React.FC<EditorProps<any>>;
    copy?: boolean;
}

interface DocumentDetailsFieldProps {
    document: IndexDocument;
    field: DocumentDetailsFieldType;
}

const documentFields: DocumentDetailsFieldType[] = [
    { attribute: "id", copy: true },
    {
        attribute: "sysno",
        transform: (value) => <ExternalLinkButton.MzkCatalog value={value} />,
        copy: true,
    },
    { attribute: "barcode", copy: true },
    { attribute: "volume", copy: true },
    { attribute: "volume_number", copy: true },
    { attribute: "volume_year", copy: true },
    { attribute: "volume_year_number", copy: true },
    { attribute: "bundle", copy: true },
    { attribute: "bundle_number", copy: true },
    { attribute: "cnb", copy: true },
    { attribute: "language", copy: true },
    { attribute: "signature", copy: true },
    { attribute: "isxn", copy: true },
    {
        attribute: "added_at",
        transform: formatDatetime,
    },
    { attribute: "added_by" },
    {
        attribute: "indexed_at",
        transform: formatDatetime,
    },
    {
        attribute: "category_path",
        transform: createCategoryPathLabel,
        editor: CategoryPathEditor,
    },
    {
        attribute: "document_flags",
        transform: (value) => <DocumentFlagTag.List flags={value} column />,
    },
    {
        attribute: "skc_sysno",
        transform: (value) => <ExternalLinkButton.SckCatalog value={value} />,
        copy: true,
    },
    {
        attribute: "cnb_sysno",
        transform: (value) => <ExternalLinkButton.CnbCatalog value={value} />,
        copy: true,
    },
    {
        attribute: "digitization_registry_id",
        transform: (value) => (
            <ExternalLinkButton.DigitizationRegistry value={value} />
        ),
        copy: true,
    },
    {
        attribute: "digitization_state",
        transform: (value) => <DigitizationStateTag state={value} />,
    },
    { attribute: "page_count" },
    { attribute: "page_count_catalog" },
    {
        attribute: "page_count_manual",
        default: "-",
        editor: NumberEditor,
    },
    { attribute: "page_count_predicted" },
    // { attribute: "dedicated_scanner" },
    {
        attribute: "extraction_priority",
        transform: (value) => <ExtractionPriorityTag priority={value} />,
        editor: ExtractionPriorityEditor,
    },
    { attribute: "digitization_list_id" },
    {
        attribute: "material_type",
        transform: (value) => <MaterialTypeTag materialType={value} />,
    },
    {
        attribute: "issuance_type",
        transform: (value) => <IssuanceTypeTag issuanceType={value} />,
    },
    { attribute: "title", copy: true },
    { attribute: "size", copy: true },
    {
        attribute: "location_uri",
        transform: (value) => (
            <a href={value} target="_blank">
                {value}
            </a>
        ),
    },
    { attribute: "publisher", copy: true },
    { attribute: "publishing_place", copy: true },
    { attribute: "publishing_year", copy: true },
    { attribute: "languages", copy: true },
    {
        attribute: "frequencies",
        transform: (fs: Frequency[]) =>
            fs
                .map((f) =>
                    f.date
                        ? `"${f.date}": "${f.frequency}"`
                        : `"${f.frequency}"`
                )
                .join(", "),
        copy: true,
    },
];

interface DocumentsDetailsProps {}

const DocumentsDetails: React.FC<DocumentsDetailsProps> = () => {
    const { expandedRows, items, onRowCollapse } = useDocuments();

    return (
        <div>
            {items
                .filter((document) => document.id in expandedRows)
                .map((document) => (
                    <Dialog
                        id={`document-details-${document.id}`}
                        key={`document-details-${document.id}`}
                        style={{
                            maxWidth: "80%",
                            minWidth: "600px",
                        }}
                        visible={true}
                        onHide={() => onRowCollapse(document)}
                        modal={false}
                    >
                        <DocumentDetails document={document} />
                    </Dialog>
                ))}
        </div>
    );
};

interface DocumentDetailsProps {
    document: IndexDocument;
}

const DocumentDetails: React.FC<DocumentDetailsProps> = ({ document }) => {
    const { t } = useTranslation("documents");

    const isUnset = (field: DocumentDetailsFieldType) => {
        return (
            (!(field.attribute in document) ||
                document[field.attribute] === null ||
                (Array.isArray(document[field.attribute]) &&
                    document[field.attribute].length === 0)) &&
            field.default === undefined
        );
    };

    return (
        <div className="flex flex-column gap-3">
            <LabeledComponentsBox
                labels={documentFields.map((field) =>
                    t(`properties.${field.attribute}`)
                )}
                components={documentFields.map(
                    (field, index) =>
                        !isUnset(field) && (
                            <DocumentDetailsItem
                                key={index}
                                field={field}
                                document={document}
                            />
                        )
                )}
                ignoreNotProvided
            />
            <DocumentDetailsFooter document={document} />
        </div>
    );
};

const DocumentDetailsItem: React.FC<DocumentDetailsFieldProps> = ({
    document,
    field,
}) => {
    const { t } = useTranslation("documents");
    const { showInfo } = useNotification();
    const { updateDocumentAttribute } = useDocuments();
    const { hasPermission, hasOwnerPermission } = useAccess();

    const [editMode, setEditMode] = useState<boolean>(false);

    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value);
        showInfo(t("copied-to-clipboard", { ns: "app" }), value);
    };

    const onEditorCallback = (value: any) => {
        if (field.attribute === "category_path") {
            updateDocumentAttribute(
                document.id,
                "category_id",
                value[0].category_id
            );
        } else if (
            ["extraction_priority", "page_count_manual"].includes(
                field.attribute
            )
        ) {
            updateDocumentAttribute(
                document.id,
                field.attribute as UpdateAttributes,
                value
            );
        }
        setEditMode(false);
    };

    if (editMode) {
        return (
            <field.editor
                value={document[field.attribute]}
                editorCallback={onEditorCallback}
                cancelCallback={() => setEditMode(false)}
            />
        );
    }

    const hasEditPermission = () => {
        return (
            hasPermission(ActionType.EditDatatableAll) ||
            hasOwnerPermission(
                ActionType.EditDatatableOwn,
                OwnedItemType.IndexDocument,
                document
            )
        );
    };

    return (
        <div key={field.attribute} className="flex align-items-center gap-2">
            {field.transform ? (
                field.transform(document[field.attribute])
            ) : (
                <span>{document[field.attribute] || field.default}</span>
            )}
            {field.editor && hasEditPermission() && (
                <Button
                    className="p-1 text-xs align-content-center text-yellow-500"
                    text
                    onClick={() => setEditMode(true)}
                >
                    <i className="pi pi-pencil" />
                </Button>
            )}
            {field.copy && (
                <Button
                    className="p-1 text-xs align-content-center text-cyan-500"
                    text
                    onClick={() =>
                        copyToClipboard(document[field.attribute] as string)
                    }
                >
                    <i className="pi pi-copy" />
                </Button>
            )}
        </div>
    );
};

const DocumentDetailsFooter: React.FC<DocumentDetailsProps> = ({
    document,
}) => {
    const { t } = useTranslation("documents");
    const { deleteDocument } = useDocuments();
    return (
        <div className="flex justify-content-between">
            <Button
                className=""
                rounded
                outlined
                severity="danger"
                icon="pi pi-trash"
                label={t("actions.delete.label", { count: 1 })}
                onClick={() => deleteDocument(document.id)}
            />
            <Button
                className="text-blue-600"
                rounded
                outlined
                label={t("internal-link.show-in-catalog-records", {
                    ns: "app",
                })}
                icon="pi pi-external-link"
                iconPos="right"
                onClick={() =>
                    window.open(
                        `catalog-records?index_name=catalog_records&id=${document.sysno}`,
                        "_blank"
                    )
                }
            />
        </div>
    );
};

export default DocumentsDetails;
