import { sortFacetDescByKey } from "../data/factories/EsQueriesFactory";

const documents_index_settings = {
    index_name: "documents",
    search_attributes: [
        { field: "title", weight: 3 },
        "sysno",
        "barcode",
        "cnb",
        "signature",
        "isxn",
        "publisher",
    ],
    result_attributes: [
        "id",
        // Main identifiers
        "sysno",
        "barcode",
        "volume",
        "volume_number",
        "volume_year",
        "volume_year_number",
        "bundle",
        "bundle_number",
        // Librarian identifiers
        "cnb",
        "language",
        "signature",
        "isxn",
        // VERA properties
        "added_at",
        "added_by",
        "indexed_at",
        "category_id",
        "category_ids",
        "category_path",
        "document_flags",
        // Catalog properties
        "skc_sysno",
        "skc_diff",
        "cnb_sysno",
        "cnb_diff",
        "digitization_registry_id",
        "digitization_state",
        // Page count
        "page_count",
        "page_count_catalog",
        "page_count_manual",
        "page_count_predicted",
        // Extraction properties
        "dedicated_scanner",
        "extraction_priority",
        "extraction_ids",
        "digitization_list_id",
        // Other properties
        "material_type",
        "issuance_type",
        "title",
        "size",
        "location_uri",
        // Publication properties
        "publisher",
        "publishing_place",
        "publishing_year",
        "languages",
        "frequencies",
    ],
    highlight_attributes: ["title"],
    sorting: {
        _newest: { field: "added_at", order: "desc" },
        _oldest: { field: "added_at", order: "asc" },
        _last_edited: [{ field: "indexed_at", order: "desc" }],
        _priority: [
            { field: "extraction_priority", order: "desc" },
            { field: "indexed_at", order: "desc" },
        ],
        _title_asc: { field: "title", order: "asc" },
        _title_desc: { field: "title", order: "desc" },
    },
    facet_attributes: [
        {
            attribute: "id",
            field: "id",
            type: "numeric",
            translateAs: "number",
        },
        {
            attribute: "document_flags",
            field: "document_flags",
            type: "string",
            translateAs: "type",
            tType: "document-flag",
        },
        {
            attribute: "digitization_state",
            field: "digitization_state",
            type: "string",
            translateAs: "type",
            tType: "digitization-state",
        },
        {
            attribute: "material_type",
            field: "material_type",
            type: "string",
            translateAs: "type",
            tType: "material-type",
        },
        {
            attribute: "issuance_type",
            field: "issuance_type",
            type: "string",
            translateAs: "type",
            tType: "issuance-type",
        },
        {
            attribute: "added_by",
            field: "added_by",
            type: "string",
            translateAs: "text",
        },
        {
            attribute: "category_ids",
            field: "category_ids",
            size: 100,
            type: "numeric",
            translateAs: "text",
        },
        {
            attribute: "extraction_ids",
            field: "extraction_ids",
            type: "numeric",
            facetQuery: sortFacetDescByKey,
            translateAs: "text",
        },
        {
            attribute: "digitization_list_id",
            field: "digitization_list_id",
            type: "numeric",
            facetQuery: sortFacetDescByKey,
            translateAs: "text",
        },
        {
            attribute: "page_count",
            field: "page_count",
            type: "numeric",
            translateAs: "text",
        },
        {
            attribute: "volume_number",
            field: "volume_number",
            type: "numeric",
        },
        {
            attribute: "volume_year_number",
            field: "volume_year_number",
            type: "numeric",
        },
        {
            attribute: "bundle_number",
            field: "bundle_number",
            type: "numeric",
        },
        {
            attribute: "page_count_manual",
            field: "page_count_manual",
            type: "numeric",
        },
        {
            attribute: "page_count_catalog",
            field: "page_count_catalog",
            type: "numeric",
        },
        {
            attribute: "page_count_predicted",
            field: "page_count_predicted",
            type: "numeric",
        },
    ],
};

export default documents_index_settings;
